import { Box} from "@mui/material";

export function CloudPage() {
    return (
        <>
            <Box component='img' src='/images/binaryTube.jpg' sx={{ width: '200px' }} alt='Azure Cloud Computing' />
            <h1>Azure Cloud Computing</h1>
            <Box>
                <p>
                Moving your data and application into the Azure cloud and using it to monitor is a good idea. It also sets
                    you up to manage the development process via CI/CD. You can use VSTS to manage, view, control the changes to your system.
                </p>
            </Box>

            <Box>
                <h3>Examples of why you may want to move to cloud:</h3>
                <ul>
                    <li>You dont have to worry about managing and maintaining hardware and operating systems.</li>
                    <li>Has built in systems to manage redundancy and provide alerts and monitoring if things go wrong.</li>
                    <li>Reduces a lot of development time since they have a lot of solutions already built and designed.</li>
                    <li>Has built in process for managing and approving software changes and releases.</li>
                    <li>Can setup automation to automatically build and deploy your code to various environments upon check-in.</li>
                    <li>Ability to automatically view previous deploys and roll back your code at the push of a button.</li>
                </ul>
            </Box>

            <Box>
                <Box component='img' src='/images/projects/cicd.png' sx={{ height: '200px', padding: '10px' }} alt='Pipeline' />
                <Box component='img' src='/images/projects/azureMonitor.png' sx={{ height: '200px', padding: '10px' }} alt='Azure Monitor' />
            </Box>

        </>
    );
};