import { Box, Grid } from "@mui/material";

export function ProcessPage() {
    return (
        
             <>
            <h1>Process</h1>

            <Grid container spacing={4} direction='column'>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/processImprovement.jpg' style={{ width: '150px' }} alt='Requirements' />
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ maxWidth: '500px' }}>
                    <h3>Identify the needs</h3>
                    <ul>
                        <li>What are your pain points?</li>
                        <li>What risks do you want to mitigate?</li>
                        <li>What task can be automated?</li>
                        <li>What would help you move faster?</li>
                        <li>What is limiting you from growing or being scalable?</li>
                    </ul>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems='center'>
                    <Grid item xs={12} md={3}>
                        <img src='/images/integration_small.png' style={{ width: '150px' }} alt='Integrations' />
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ maxWidth: '500px' }}>
                    <h3>System Integration</h3>
                    <ul>
                            <li>Is there data from an external source that would be nice to have?</li>
                            <li>Are you manually entering data that you could automate?</li>
                            <li>Is there data you would like to push into Facebook, Google, Outlook</li>
                        </ul>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems='center'>
                    <Grid item xs={12} md={3}>
                        <img src='/images/webApp_small.png' style={{ width: '150px' }} alt='Web Apps' />
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ maxWidth: '500px' }}>
                    <h3>Web Applications and APIs</h3>
                    <ul>
                            <li>Need a web application to allow customers to enter data or orders?</li>
                            <li>Need an application for internal users to use and share data?</li>
                            <li>Are you storing data in Excel that should be in a shared web app?</li>
                            <li>Do you have data that other systems could use to integrate with you?</li>
                        </ul>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems='center'>
                    <Grid item xs={12} md={3}>
                        <img src='/images/automation_small.png' style={{ width: '150px' }} alt='Automation' />
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ maxWidth: '500px' }}>
                    <h3>Tools &amp; Automation</h3>
                    <ul>
                            <li>What tasks take away from you doing more important things?</li>
                            <li>Where are errors occurring that automating it could prevent them?</li>
                            <li>What data or process would speed you up if you didn't have to wait?</li>
                            <li>Would it be useful if you could automate or control a switch or valve?</li>
                        </ul>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems='center'>
                    <Grid item xs={12} md={3}>
                        <img src='/images/reports_small.png' style={{ width: '150px' }} alt='Reports' />
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ maxWidth: '500px' }}>
                    <h3>Reporting &amp; Monitoring</h3>
                    <ul>
                            <li>Anything that you would want to be notified or alerted about?</li>
                            <li>Can reporting and data anlysis help you make better decisions?</li>
                            <li>How can you distribute those reports or data? </li>
                            <li>Would the ability to monitor or analyze a sensor help you? </li>
                        </ul>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems='center'>
                    <Grid item xs={12} md={3}>
                        <img src='/images/binaryTube_small.png' style={{ width: '150px' }} alt='Cloud Computing' />
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ maxWidth: '500px' }}>
                    <h3>Azure Cloud Computing</h3>
                    <ul>
                            <li>Can the cloud reduce risk or increase performance?</li>
                            <li>Can your development team take advantage of improved DevOps?</li>
                            <li>Is your existing system scalable if demand grows?</li>
                            <li>Is improve redundancy and system monitoring useful?</li>
                        </ul>
                    </Grid>
                </Grid>

               

                </Grid>
                </>

           
    );
};