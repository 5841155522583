import { Box} from "@mui/material";

export function HealthPage() {
    return (
        <>
            <Box component='img' src='/images/smartHome.jpg' sx={{ width: '200px' }} alt='SmartHome' />
            <h1>Health</h1>

            <Box>
                <h3>SmartHomes provide:</h3>
                <ul>
                    <li>Determine if someone is taking their medication.</li>
                    <li>Determine if someone has gotten out of bed that day.</li>
                    <li>Monitor if someone is walking around using motion detectors.</li>
                    <li>Webcam to monitor if someone is okay.</li>
                    <li>Turn on / off power outlets based on certain conditions</li>
                    <li>Monitor when shower is on</li>
                    <li>Monitor toilet flushes and bathroom usage (motion detector)</li>
                    <li>Monitor changing clothes (sensors on drawers and closet)</li>
                    <li>Monitor motion / vibration of walker</li>
                    <li>Monitor when they go to bed and wake up</li>
                    <li>Monitor and send text when they go into certain areas of house (basement) or leave the house.</li>
                    <li>Record weight scale and blood pressure measurements </li>
                    <li>Pressure pad to know if someone is in bed or chair or wheelchair</li>
                    <li>Coming Soon – Record heart rate</li>
                    <li>Coming Soon – Life 360  - ability to monitor / track via GPS</li>
                    <li>Open Doors automatically </li>
                    <li>Monitor  / Measure workouts</li>
                    <li>Remind someone when they need to use the bathroom</li>
            </ul>
            </Box>
        </>
    );
};