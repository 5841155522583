import { createTheme } from '@mui/material/styles'
import type { } from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

const theme = createTheme({
    palette: {
        background: { default: '#EFEEEF' },
        primary: { light: '#518fd5', main: '#0062A3', dark: '#003974', contrastText: '#fff' },
        secondary: { light: '#66e0ff', main: '#00AEEF', dark: '#007fbc', contrastText: '#000' }
    },
    status: {
        danger: '#ba000d'
    },
    typography: {
        h1: { fontFamily: "Roboto", fontWeight: 300, fontSize: "2rem", lineHeight: 1.167, letterSpacing: "-0.01562em" },
        h2: { fontFamily: "Roboto", fontWeight: 300, fontSize: "1rem", lineHeight: 1.167, letterSpacing: "-0.01562em" },
        h3: { fontFamily: "Roboto", fontWeight: 300, fontSize: "0.5rem", lineHeight: 1.167, letterSpacing: "-0.01562em" }
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                },
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ECECEC',
                },
            }
        }
    }
});

export default theme;