import { Box } from "@mui/material";

export function EfficiencyPage() {
    return (
        <>
            <Box component='img' src='/images/smartHome.jpg' sx={{ width: '200px' }} alt='SmartHome' />
            <h1>Efficiency</h1>
            
            <Box>
                <h3>SmartHomes provide:</h3>
                <ul >
                    <li>Control thermostat based on time, outside temp, room activity</li>
                    <li>Monitor temperature in any / each room or area of house</li>
                    <li>Control air vents based on time of day or motion detectors</li>
                    <li>Measure power being consumed by whole house or single device</li>
                    <li>Monitor when and where water is being used.</li>
                    <li>Turn power and devices off when not in use / nobody is around</li>
                    <li>Reminders when it is time to change air filter, smoke detector batteries, check fire extinguisher</li>
                    <li>Open –Close – Rotate blinds to block sun, let in light</li>
                    <li>Turn off hot water heater when hit vacation mode – auto turns back on when you are coming back (based on button from phone, preset date, or detection of your arrival.</li>
                </ul>
            </Box>
        </>
    );
};