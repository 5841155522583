import { Box} from "@mui/material";

export function ComfortPage() {
    return (
        <>
            <Box component='img' src='/images/smartHome.jpg' sx={{ width: '200px' }} alt='SmartHome' />
            <h1>Comfort</h1>

            <Box>
                <h3>SmartHomes provide:</h3>
                <ul>
                    <li>Control exterior & interior Lights by timers, tablet or sensor activity.</li>
                    <li>Add dimmer control to bulbs</li>
                    <li>Control Fans by timers, tablet or sensor activity.</li>
                    <li>View and control Garage Door Open / close by tablet or sensor activity.</li>
                    <li>Text when Garage Door left up after 8pm</li>
                    <li>Receive notification on phone when doorbell rings and see person via web cam</li>
                    <li>Lock and unlock door to let people in at certain times without a key.</li>
                    <li>Voice control of devices (coming in near future)</li>
                    <li>Configure house when you are coming or leaving house</li>
                    <li>Configure house when you are going to bed or waking up.</li>
                    <li>Replace the manual switches with smart switches</li>
                    <li>Play various audio or music through speakers are various times</li>
                    <li>Propane tank monitor</li>
                    <li>BBQ temp monitor </li>
                    <li>Access recipes from tablet mounted in the kitchen</li>
                    <li>Control Christmas lights or monitor water in Christmas tree</li>
                </ul>
            </Box>
        </>
    );
};