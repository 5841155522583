import { Box} from "@mui/material";

export function IntegrationsPage() {
    return (
        <>
            <Box component='img' src='/images/binaryTube.jpg' sx={{ width: '200px' }} alt='data integration' />
            <h1>System Integrations</h1>
            <Box>
            <p>
                    Integrations allow your system to connect to other systems to either send or retrieve data. There are various ways to
                    connect to other systems including SOAP, REST, exporting CSV files, or EDI.
                </p>
            </Box>

            <Box>
                <h3>Examples of why you may want to integrate:</h3>
                <ul>
                    <li>Post data to a site such as Facebook or Google Calendar.</li>
                    <li>Get address or distance details from Google.</li>
                    <li>Status tracking for orders from Amazon.</li>
                    <li>Retrieve data from sensors and switches or control outlets and switches.</li>
                    <li>Exchange data with your business partners.</li>
                    <li>If you do any shipping, you can automate the sending of shipments to a 3PL.</li>
                </ul>
            </Box>

            <Box>
                <Box component='img' src='/images/projects/facebook.png' sx={{ height: '200px', padding: '10px' }} alt='Facebook' />
                <Box component='img' src='/images/projects/googleMaps.jpg' sx={{ height: '200px', padding: '10px' }} alt='Google' />
                <Box component='img' src='/images/projects/amazon.png' sx={{ height: '200px', padding: '10px' }} alt='Amazon' />
            </Box>

        </>
    );
};