import { Box, Card, Grid } from "@mui/material";
import { Link } from "react-router-dom";

export function ResumePage() {
    return (
        <>
        <h1>Resume Page</h1>
            <Grid container spacing={4} direction='column' >
                <Grid container item direction='row'>
                    <Grid md={2}>
                        <h4>Experience</h4>
                    </Grid>
                    <Grid md={10}>

                        <Card sx={{padding:'10px'}}>
                            <Grid container direction='row'>
                                <Grid item md={4}><h4>10/15 - Present</h4></Grid>
                                <Grid item md={4}><h4>BlueGrace Logisitics</h4></Grid>
                                <Grid item md={4}><h4>Tampa, FL</h4></Grid>
                            </Grid>

                            <Box sx={{marginTop:'5px',fontStyle:'italic' }}>Integration Architect</Box>

                            <div>
                                <ul>
                                    <li>Responsible for the implementation, development and operation of over 100 integrated customers into the BlueShip system via SOAP, REST and File import.</li>
                                    <li>Developed API documentation via OpenAPI (Swagger) and ReDoc</li>
                                    <li>Azure cloud - Web API, Functions, Service Bus, Azure Storage, Application Insights</li>
                                    <li>Use Azure DevOps to manage the work and GIT CI\CD pipeline.</li>
                                    <li>React, Node, Typescript, C#, ASP.NET Core, Mongo, SQL Server</li>

                                </ul>
                            </div>
                        </Card>
                        <br/>
                        <Card sx={{padding:'10px'}}>
                            <Grid container direction='row'>
                                <Grid item md={4}><h4>07/03 - Present</h4></Grid>
                                <Grid item md={4}><h4>ColbrenSolutions LLC</h4></Grid>
                                <Grid item md={4}><h4>Tampa, FL</h4></Grid>
                            </Grid>
                            <Box sx={{marginTop:'5px',fontStyle:'italic' }}>Solutions Developer / System Architect</Box>

                            <div>
                            <ul>
                            <li>Develop applications to support youth and adult sports leagues.</li>
                            <li>Work projects to help small businesses improve productivity.</li>
                            <li>Development training to help small businesses improve productivity.</li>
                            <li>Develop integrations to allow data to be shared or consumed between 2 systems.</li>
                            <li>Develop systems for data capture, monitor and data analysis.</li>
                            <li>Azure cloud - Web API, Service Bus, Azure Storage, Application Insights, SQL Azure</li>
                            <li>Use Azure DevOps to manage the work and GIT CI\CD pipeline.</li>
                            <li>Develop apps to convert images to CMYK and insert into PDF docs for printing.</li>
                            <li>SendGrid, Twilio, Auth0, DataDog, Stripe </li>
                            <li>React, Node, Typescript, C#, ASP.NET Core, Mongo, SQL Server</li>
                        </ul>
                        <ul>
                            <li>See my portfolio <Link to="/portfolio">here</Link></li>
                        </ul>
                            </div>
                        </Card>

                        <br/>
                        <Card sx={{padding:'10px'}}>
                            <Grid container direction='row'>
                                <Grid item md={4}><h4>01/13 - 09/15</h4></Grid>
                                <Grid item md={4}><h4>Ubisense</h4></Grid>
                                <Grid item md={4}><h4>Tampa, FL</h4></Grid>
                            </Grid>
                            <Box sx={{marginTop:'5px',fontStyle:'italic' }}>System Architect</Box>

                            <div>
                            <ul>
                            <li>Designed and developed SmartHome system to provide care for patients at home.</li>
                            <li>Responsible for gathering requirements, designing, building and testing solution.</li>
                            <li>Includes Windows services, web applications, mobile devices, UWB tracking, ZWave sensors, connecting to external systems and APIs.Built with .NET – MySQL-jQuery</li>
                            <li>Installed in 7 sites. Collects and processes sensor and admin data to monitor and assist patients with traumatic brain injury at hospital and in homes.</li>
                            <li>Integrate various technologies including SmartThings, Ubisense RTLS, Withings, Google Calendar.</li>

                        </ul>
                            </div>
                        </Card>
                        <br/>
                        <Card sx={{padding:'10px'}}>
                            <Grid container direction='row'>
                                <Grid item md={4}><h4>01/12 - 01/13</h4></Grid>
                                <Grid item md={4}><h4>SAIC</h4></Grid>
                                <Grid item md={4}><h4>Kennedy Space Center, FL</h4></Grid>
                            </Grid>
                            <Box sx={{marginTop:'5px',fontStyle:'italic' }}>System Engineer</Box>

                            <div>
                            <ul>
                            <li>System Engineer to renovate KSC for the Space Shuttle successor. </li>
                            <li>Responsible for Requirements Development and Verification processes and procedures. </li>
                            <li>Implement and manage use of requirements management tool (Cradle). </li>
                            <li>Develop software applications to support anomaly detection of telemetry measurements.</li>
                        </ul>
                            </div>
                        </Card>
                        <br/>
                        <Card sx={{padding:'10px'}}>
                            <Grid container direction='row'>
                                <Grid item md={4}><h4>08/10 - 12/11</h4></Grid>
                                <Grid item md={4}><h4>JHT Inc.</h4></Grid>
                                <Grid item md={4}><h4>Orlando, FL</h4></Grid>
                            </Grid>
                            <Box sx={{marginTop:'5px',fontStyle:'italic' }}>Senior Software Developer</Box>

                            <div>
                            <ul>
                            <li>Support overall system design enhancements and improvements</li>
                            <li>Develop C# libraries to support simulation of voice and http/web signals </li>
                            <li>Develop C# WinForms to simulate submarine radio room devices </li>
                        </ul>
                            </div>
                        </Card>

                        <br/>
                        <Card sx={{padding:'10px'}}>
                            <Grid container direction='row'>
                                <Grid item md={4}><h4>05/00 - 08/10</h4></Grid>
                                <Grid item md={4}><h4>United Space Alliance</h4></Grid>
                                <Grid item md={4}><h4>Kennedy Space Center, FL</h4></Grid>
                            </Grid>
                            <Box sx={{marginTop:'5px',fontStyle:'italic' }}>System Engineer</Box>

                            <div>
                            <ul>
                            <li>Create processes and tools for engineers to write application software</li>
                            <li>Support design reviews, conOps, SRR, SDR for new command and cotrol system.</li>
                            <li>Support requirements development for Space Shuttle command and control system and data anaylsis tools.</li>
                            <li>Managed requirements and testing to upgrade the entire Shuttle propulsion ground system to PLCs</li>
                        </ul>
                            </div>

                            <Box sx={{marginTop:'5px',fontStyle:'italic' }}>Software Developer</Box>

                            <div>
                            <ul>
                            <li>Special projects - develop and implement various software tools and applications to improve efficiency within engineering org. C#.NET &amp; ASP.NET </li>
                        </ul>
                            </div>

                            <Box sx={{marginTop:'5px',fontStyle:'italic' }}>Propulsion System Engineer</Box>

                            <div>
                            <ul>
                            <li>Senior engineer on Space Shuttle Main Propulsion System</li>
                            <li>Perform real-time and post data analysis of Shuttle telementry</li>
                            <li>Create presentations and present to senior management</li>
                            <li>Monitor and control of the shuttle from the firing room for testing, propellant loading and launch operations</li>
                            <li>
                                Develop and execute processes and procedures to support testing, maintenance, and troubleshooting
                                using MIL spec, wire lists, electrical and mechanical schematics
                            </li>
                            <li>Task team leader for various Space Shuttle Main Propulsion System operations.</li>
                            <li>Mentor and train interns and new employees.</li>

                        </ul>
                            </div>
                        </Card>
                    </Grid>
                    <br/>

                    <Grid md={2}>
                    <br/>
                        <h4>Education</h4>
                    </Grid>
                    <Grid md={10}>
                    <br/>
                        <Card sx={{padding:'10px'}}>
                            <Grid container direction='row'>
                                <Grid item md={4}><h4>08/02 - 10/04</h4></Grid>
                                <Grid item md={4}><h4>Webster University</h4></Grid>
                                <Grid item md={4}><h4>Merritt Island, FL</h4></Grid>
                            </Grid>

                            <Box sx={{marginTop:'5px',fontStyle:'italic' }}>M.S. Computer Science</Box>

                            <div>
                            <ul>
                            <li>3.86 GPA</li>
                        </ul>
                            </div>
                        </Card>
                        <br/>
                        <Card sx={{padding:'10px'}}>
                            <Grid container direction='row'>
                                <Grid item md={4}><h4>08/93 - 05/00</h4></Grid>
                                <Grid item md={4}><h4>University of South Florida</h4></Grid>
                                <Grid item md={4}><h4>Tampa, FL</h4></Grid>
                            </Grid>

                            <Box sx={{marginTop:'5px',fontStyle:'italic' }}>B.S. Mechanical Engineering</Box>

                            <div>
                            <ul>
                            <li>3.0 GPA (3.9 GPA over my last 16 courses)</li>
                        </ul>
                            </div>
                        </Card>
                        
                    </Grid>
                    <br/>

                    <Grid md={2}>
                    <br/>
                        <h4>Professional Trng</h4>
                    </Grid>
                    <Grid md={10}>
                    <br/>
                    <Card sx={{padding:'10px'}}>
                            <div>
                            <ul>
                                <li>USA Integrated Project Management (3 days)</li>
                                <li>Cadence Project Management (3 days)</li>
                                <li>Interpersonal Communication Skills (1 day)</li>
                                <li>Effective time Management (1 day)</li>
                                <li>7 Habits of Highly Effective People (3 days)</li>
                                <li>Leadership Development Institute training (Series of 12 classes)</li>
                                <li>Covey - Acheiving your highest priorities (1 day)</li>
                                <li>SEI Intro to CMMI (4 days)</li>
                                <li>SEI Integrated System Architecture - Lockheed Martin (4 days)</li>
                                <li>APPEL - Requirements Management Training (3 days)</li>
                                <li>APPEL - Sytem Engineering and Lifecycles (3 days)</li>

                            </ul>
                            </div>
                        </Card>
                        
                    </Grid>
                    <br/>

                    <Grid md={2}>
                    <br/>
                        <h4>Skills &amp; Experience</h4>
                    </Grid>
                    <Grid md={10}>
                    <br/>
                        <Card sx={{padding:'10px'}}>
                          
                            <div>
                            <ul>
                                    <li>Served 3 years as Upward Sports Basketball director (250+ players \ 76 coaches) </li>
                                    <li>Served 5 years as Children's Ministry Coordinator</li>
                                    <li>Participated on several Lean Six Sigma teams</li>
                                    <li>Worked as an automotive technician for BMW and Mercedes dealerships 1995-1996</li>
                                    <li>Passed ASE certification exam for brakes, electrical and a/c in 1995</li>
                                    <li>Wrote and presented a whitepaper at 2010 AIAA SpaceOps Conference</li>
                                    <li>Passed the Engineer In Training (EIT) exam in 2000 EIT #1100006186</li>
                                </ul>
                            </div>
                        </Card>
                        
                    </Grid>
                    <br/>

                    <Grid md={2}>
                    <br/>
                        <h4>Awards &amp; Honors</h4>
                    </Grid>
                    <Grid md={10}>
                    <br/>
                        <Card sx={{padding:'10px'}}>
                          
                            <div>
                            <ul>
                                        <li>NASA Bronze Medallion Award for developing a simulation application for Space Shuttle Propulsion System</li>
                                        <li>NASA New technology Award for development of web application that integrated several data sources to one location</li>
                                        <li>NASA New technology Award for tool to allow better data analysis of Space Shuttle telemetry data</li>
                                        <li>NASA New technology Award for developing software to support Lean Six Sigma effort</li>
                                        <li>Quest Award for developing and managing the removal and replacement of 8" Fill and Drain valves on the Space Shuttle</li>
                                        <li>Quest Award for participation of complex inspection of BSTRA balls that were 20 feet inside propellant feedlines</li>
                                    </ul>
                            </div>
                        </Card>
                        
                    </Grid>


                </Grid>
                
            </Grid>
        </>
    );
};