import { Box} from "@mui/material";

export function ToolsPage() {
    return (
        <>
            <Box component='img' src='/images/automation.jpg' sx={{ width: '200px' }} alt='automation' />
            <h1>Tools &amp; Automation</h1>
            <Box>
            <p>
                The purpose of tools and automation is to automate the mundane work that can be time consuming or error-prone. They may be
                background processes or there may be a screen that a user can access.
            </p>
            </Box>

            <Box>
                <h3>Examples of why you may want tools &amp; automation:</h3>
                <ul>
                <li>Parse, map, modify csv or other data files.</li>
                <li>Plugins for Outlook, Word, Excel etc to automate process.</li>
                <li>Have a timer that does routine checks and sends alerts and notifications.</li>
                <li>Automate your calendar or post to your calendar.</li>
            </ul>
            </Box>

            <Box>
                <Box component='img' src='/images/projects/fileManager.png' sx={{ height: '200px', padding: '10px' }} alt='File Manager App' />
                <Box component='img' src='/images/projects/sgcCM.png' sx={{ height: '200px', padding: '10px' }} alt='SGC App' />
                <Box component='img' src='/images/projects/desktopModeler.png' sx={{ height: '200px', padding: '10px' }} alt='Desktop Modeler App' />
            </Box>

        </>
    );
};