import { Box, Button, Drawer, Grid, IconButton, Link } from '@mui/material';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';


export function Menu() {

    const [open, setState] = useState(false);

    const navigate = useNavigate();

    const toggleDrawer = (open: any) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState(open);
    };

    function navigateHome() {
        navigate('/home');
    }

    function navigateAbout() {
        navigate('/about');
    }

    function navigatePortfolio() {
        navigate('/portfolio');
    }

    function navigateProcess() {
        navigate('/process');
    }

    function navigateContact() {
        navigate('/contact');
    }


    return (
        <Box sx={{ backgroundColor: 'white' }}>
            <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
            <Box sx={{ backgroundColor: 'white', display: { xs: 'none', md: 'block' } }}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button onClick={navigateHome}>Home</Button>
                    </Grid>

                    <Grid item>
                        <Button onClick={navigateAbout}>About</Button>
                    </Grid>

                    <Grid item>
                        <Button onClick={navigatePortfolio}>Portfolio</Button>
                    </Grid>

                    <Grid item>
                        <Button onClick={navigateProcess}>Process</Button>
                    </Grid>

                    <Grid item>
                        <Button onClick={navigateContact}>Contact</Button>
                    </Grid>

                </Grid>

            </Box>

            <Box sx={{ mr: '10px', display: { xs: 'block', md: 'none' } }}>

                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer(true)}
                    sx={{ mr: 2, display: { xs: 'block', md: 'none' } }}>
                    <MenuIcon />
                </IconButton>

                <Drawer
                    anchor="right"
                    variant="temporary"
                    open={open}
                    onClose={toggleDrawer(false)}

                >

                    <Box sx={{ width: '200px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Button onClick={navigateHome}>Home</Button>
                            </Grid>

                            <Grid item>
                                <Button onClick={navigateAbout}>About</Button>
                            </Grid>

                            <Grid item>
                                <Button onClick={navigatePortfolio}>Portfolio</Button>
                            </Grid>

                            <Grid item>
                                <Button onClick={navigateProcess}>Process</Button>
                            </Grid>

                            <Grid item>
                                <Button onClick={navigateContact}>Contact</Button>
                            </Grid>

                        </Grid>
                    </Box>
                </Drawer>

            </Box>
            </Grid>
            <Grid item xs={1}></Grid>
            </Grid>
        </Box>

    );
}