import { Box} from "@mui/material";

export function SafetyPage() {
    return (
        <>
            <Box component='img' src='/images/smartHome.jpg' sx={{ width: '200px' }} alt='SmartHome' />
            <h1>Safety</h1>

            <Box>
                <h3>SmartHomes provide:</h3>
                <ul>
                    <li>Monitor home with webcams, motion detectors, door sensors, vibration sensors.</li>
                    <li>Set off a siren, send a text message or just log to table when activity occurs.</li>
                    <li>When motion detected during certain times, have webcam take photo and text it to you.</li>
                    <li>Turn on / off devices like the stove when you are not home (because you forgot to or because kids are home)</li>
                    <li>Determine if an appliance is on or off based on how much power is being drawn. (Add specific devices)</li>
                    <li>Monitor pet activity</li>
                    <li>Monitor for flooding or leaks using moisture sensor.</li>
                    <li>Text message to phone when Smoke Alarm is going off</li>
                    <li>Text message alarm if pet leaves the house.</li>
                    <li>Monitor the opening of cabinets or refrigerator</li>
                    <li>Motion detector in lock safe.</li>
                    <li>Monitor / Lock Liquor cabinet, medicine cabinet, poisons.</li>
                </ul>
            </Box>
        </>
    );
};