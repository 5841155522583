import { Box} from "@mui/material";

export function ContactPage() {
    return (
        <>
            <h1>Contact</h1>
                <Box>
                    <h3>Email</h3>
                    <p>
                        <span><a href="mailto:jeff@colbrensolutions.com">jeff@colbrensolutions.com</a></span>
                    </p>

                </Box>
                <br/>
                <Box>
                    <h3>QR Code</h3>
                    <Box component='img' src='/images/qrcode.png' sx={{ width: '150px' }} alt='QR Code' />
                    <br/>
                    http://www.colbrensolutions.com <br />

                </Box>
        </>
    );
};