import { AppBar, Toolbar, Box, Grid} from '@mui/material';


export function Header() {

    return (
        <Box>
            <AppBar position='sticky'>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center"  >
                        <Grid item xs={8}>
                            <Box component='img' src='/images/headerLogo.png' alt='' />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
}