import { Box} from "@mui/material";

export function MonitoringPage() {
    return (
        <>
            <Box component='img' src='/images/reports.jpg' sx={{ width: '200px' }} alt='reporting' />
            <h1>Reporting &amp; Monitoring</h1>
            <Box>
            <p>
                    It is often to have applications that allow you to report on data. You may also want to monitor the data realtime and send alerts
                    or notifications whenever a criteria is met.
                </p>
            </Box>

            <Box>
                <h3>Examples of reporting and monitoring:</h3>
                <ul>
                    <li>Display charts and graphs in a maningful way.</li>
                    <li>Provide table data that is displayed in a meaningful way ot your process.</li>
                    <li>Email PDF reports of statuses to certain members.</li>
                    <li>Monitor a system and send an email or text message or even a hardwrae command when criteria is met.</li>
                </ul>
            </Box>

            <Box>
            <Box>
                <Box component='img' src='/images/projects/bloodPressUI.jpg' sx={{ height: '200px', padding: '10px' }} alt='Blood Pressure' />
            </Box>
            </Box>

        </>
    );
};