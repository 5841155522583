import { Box, Card, Grid } from "@mui/material";
import { Link } from "react-router-dom";

export function AboutPage() {
    return (
        <>
            <h1>About</h1>
            <Card sx={{ backgroundColor: 'white', padding: '20px', border: '1px solid black' }}>

                <Grid container >
                    <Grid item sx={{ padding: '30px' }}>
                        <img src="/images/jeffMay.jpg" alt="Jeff May" style={{ border: '1px solid black' }} /><br />
                        <Link to="/resume">Resume</Link><br />
                        <Link to="/portfolio">Portfolio</Link><br />
                    </Grid>

                    <Grid item>
                        <h2>Jeff May </h2>
                        <h3>Solutions Developer</h3>

                        <Box>
                            <h3>Skills:</h3>
                            <ul>
                                <li>System Engineering</li>
                                <li>Cloud Computing</li>
                                <li>Software Development</li>
                                <li>Software Application</li>
                                <li>Project Management</li>
                                <li>Problem Solving</li>
                                <li>Data Integration</li>
                                <li>Leadership and Teamwork</li>
                            </ul>
                        </Box>
                        <Box>
                            <h3>Experience:</h3>
                            <ul>
                                <li>Application Integration Architect</li>
                                <li>VA SmartHome System Architect</li>
                                <li>Space Shuttle Operations Engineer</li>
                                <li>Space Shuttle Command and Control System Engineer</li>
                                <li>KSC GroundOps System Engineer</li>
                                <li>Software Developer - See Portfolio</li>
                            </ul>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
};