import { Box, Grid } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './app.css';
import { Footer } from './common/footer';
import { Header } from './common/header';
import { Menu } from './common/menu';
import { AboutPage } from './pages/aboutPage';
import { CloudPage } from './pages/cloudPage';
import { ContactPage } from './pages/contactPage';
import { HomePage } from './pages/homePage';
import { IntegrationsPage } from './pages/integrationsPage';
import { MonitoringPage } from './pages/monitoringPage';
import { PortfolioPage } from './pages/portfolioPage';
import { ProcessPage } from './pages/processPage';
import { ResumePage } from './pages/resumePage';
import { ComfortPage } from './pages/smarthome/comfortPage';
import { EfficiencyPage } from './pages/smarthome/efficiencyPage';
import { HealthPage } from './pages/smarthome/healthPage';
import { SafetyPage } from './pages/smarthome/safetyPage';
import { SmartHomePage } from './pages/smarthome/smartHomePage';
import { ToolsPage } from './pages/toolsPage';
import { TrackingPage } from './pages/trackingPage';
import { WebAppPage } from './pages/webAppPage';

export function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Menu />
        <Box className='mainContent'>
          <Grid container spacing={2}>
            <Grid item xs={1} />

            <Grid item xs={10}>
              <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/home' element={<HomePage />} />
                <Route path='/about' element={<AboutPage />} />
                <Route path='/portfolio' element={<PortfolioPage />} />
                <Route path='/process' element={<ProcessPage />} />
                <Route path='/contact' element={<ContactPage />} />
                <Route path='/resume' element={<ResumePage />} />
                <Route path='/cloud' element={<CloudPage />} />
                <Route path='/integrations' element={<IntegrationsPage />} />
                <Route path='/monitoring' element={<MonitoringPage />} />
                <Route path='/tools' element={<ToolsPage />} />
                <Route path='/tracking' element={<TrackingPage />} />
                <Route path='/webApp' element={<WebAppPage />} />
                <Route path='/tracking' element={<TrackingPage />} />
                <Route path='/smartHome' element={<SmartHomePage />} />
                <Route path='/smartHome/comfort' element={<ComfortPage />} />
                <Route path='/smartHome/efficiency' element={<EfficiencyPage />} />
                <Route path='/smartHome/safety' element={<SafetyPage />} />
                <Route path='/smartHome/health' element={<HealthPage />} />
              </Routes>
            </Grid>

            <Grid item xs={1} />
          </Grid>
        </Box>
        <Footer />
      </BrowserRouter>
    </>
  );
}
