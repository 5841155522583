import { Box, Grid } from "@mui/material";

export function PortfolioPage() {
    return (
        <>
            <h1>Portfolio for Jeff May</h1>

            <Grid container spacing={4} direction='column'>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/colbrenSportsDemo.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>Colbren Sports</h3>
                        <p style={{maxWidth:'500px'}}>
                            <span>Colbren - </span> 
                            Created to improve youth sports by creating tools and processes to manage athletes, rosters and sports cards.
                        </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/colbrenMoney.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>Colbren Money</h3>
                        <p style={{maxWidth:'500px'}}>
                            <span>Colbren - </span> 
                            Developed an application for managing personal finances. The focus of the product is reporting
                            and management tools driven to help people get out of debt.
                        </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/colbrenRunners.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>Colbren Runners</h3>
                        <p style={{maxWidth:'500px'}}>
                            <span>Colbren - </span> 
                            Developed an application for race teams to track and compare races and trainings for its runners.
                    Also contains an API where other applications can access general race information. Each team has a home page where they can post upcoming races, documents,
                    news and announcements.
                        </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/peninsular_small.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>Peninsular Paper Backup System</h3>
                        <p style={{maxWidth:'500px'}}>
                        <span>Peninsular Paper - </span> Developed an application that synched data from a Cobol data base into a SQL Server database.
                    The application provided a backup system for creating orders and included reports and creation and download of pick tickets and packing slips.
                        </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/noScreenshot.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>Plaza Electronics</h3>
                        <p style={{maxWidth:'500px'}}>
                        <span>Plaza Electronics - </span> Provided a timecard system for employees to log in and out with generated reports of hours.
                    Also included ability for technicians to create quotes that were emailed to customers for approval. Once the quotes were approved, the techs were able to
                    document their work and hours, upload photos. The system could create commission reports and track which techs had been paid.
                        </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/colbrenHomes.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>Colbren Homes</h3>
                        <p style={{maxWidth:'500px'}}>
                            <span>Colbren - </span> 
                            Smart screens that users could would have throughout their house to help them interact with their home.
                        </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/dashboardUI_small.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>VA SmartHome</h3>
                        <p style={{maxWidth:'500px'}}>
                        <span>US Dept of Veteran Affairs -</span> Development of a smarthome that serves as an electronic caregiver for patients at home. The
                    home knows where patients are and what they are supposed to be doing. It helps the patients
                    to do the things they should be doing such as excercise and brushing teeth and then generates reports
                    on their activities for clinicians and caregivers. This house uses several technologies.
                </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/colbren.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>Colbren Data Retrieval</h3>
                        <p style={{maxWidth:'500px'}}>
                        <span>NASA - </span>This application performs data retrievals of Space Shuttle vehicle telemetry and displays it to the user
                    in ways that the engineer can best review and analyze it.
                    The key feature of this tool is that it is geared to make retrievals and analysis very quick and easy for the engineer.
                    Data is presented as plots, tables and in raw text format. It allows overlays of the same measurements from multiple days of propellant tanking
                    events so that engineers can compare variation in the data. It also provides notifications to the user if the data was
                    determined to be out of family. This tool is used heavily by the engineering community for each Shuttle launch. <br />
                    <span>Received a NASA award for this tool.</span>
                
                        </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/desktopModeler.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>Desktop Simulator</h3>
                        <p style={{maxWidth:'500px'}}>
                            <span>Colbren - </span> 
                            <span>NASA - </span>Simulation interface for system engineers to train and look at the model of their engineering system in views that are more
                    relevent to their needs and which support training. The simulator works on the standard desktop.
                    The simulator is used for training, process development, process analysis, and detailed system analysis.
                    The demo and screenshots include very simple shuttle wiring schematics and
                    cross sections. This project was developed as a working prototype and ended with the end of the Space Shuttle program.
                    <br /><a href='http://arc.aiaa.org/doi/pdf/10.2514/6.2010-2346'>Published Paper</a>
                </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/pacaRunning.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>PACA Running</h3>
                        <p style={{maxWidth:'500px'}}>
                        <span>Park Avenue Christian - </span>This web application was created for the Park Avenue Running Team. It provides data such as the next scheduled run, dynamically lists the runners by age,
                    and allows each runner to log their training and races. The site accepts CSV upload of race results to allow a coach to quickly and easily update the runners
                    race times.
                        </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/colbrenPrayer.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>Colbren Prayer</h3>
                        <p style={{maxWidth:'500px'}}>
                        <span>Park Avenue Baptist - </span> Developed an application for managing personal finances. The focus of the product is reporting
                    and management tools driven to help people get out of debt.
                        </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/upward.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>Upward</h3>
                        <p style={{maxWidth:'500px'}}>
                        <span>Park Avenue Baptist - </span>This web application was created to provide league information for the Upward Basketball League.
                    This application has lots of admin features including coach registration, ability to import teams and game schedules
                    and on-line registration via Paypal.</p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/pineDerby.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>Pine Derby Application</h3>
                        <p style={{maxWidth:'500px'}}>
                        <span>Sov. Grace Church - </span>This application is used to manage a pine derby race. It is used to register cars and racers. It then determines the heat
                    lineups that spreads cars out over the entire race, with each car racing one time in each lane. Different divisions can race.
                    The cars in each division will only race each other, but their heats will be interleaved. The application will immediately display
                    the time and position of the cars after each heat. The program will also record the results and score the winner after the race.
                </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/sgcCM.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>SGC Children's Ministry</h3>
                        <p style={{maxWidth:'500px'}}>
                        <span>Sov. Grace Church - </span>This web application was created for the Children's Minsitry coordinator to manage the schedule, the teachers and the curriculum. It works in conjunction
                    with a web service to send weekly reminders to teachers when they are scheduled to teach and what lesson and props they will be provided.
                </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/serus.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>SERUS</h3>
                        <p style={{maxWidth:'500px'}}>
                        <span>U.S. Air Force - </span>This web application was created for AFAMS (Air Force Agency for Modeling and Simulation) to manage the workflow
                    of vetting standards.</p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/moad.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>MOAD</h3>
                        <p style={{maxWidth:'500px'}}>
                        <span>NASA - </span>This web application provides an integrated data tool for Space Shuttle propulsion engineers.
                    It groups and links data in ways that are relevent for the engineer and his workflow. It combines data from several different
                    sources into a single app. For example, by clicking on a valve on a graphical schematic, it will take you to a page that
                    provides all associated info and links to the device such as electrical schematics, part information, telemetry measurements etc. <br />
                    <span>Received a NASA award for this tool.</span>
                </p>
                    </Grid>
                </Grid>

                <Grid item container direction='row' alignItems="center">
                    <Grid item xs={12} md={3}>
                        <img src='/images/projects/mpsDefects.png' style={{ width: '150px' }} alt='ColbrenSports' />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <h3>MPS Defect Log</h3>
                        <p style={{maxWidth:'500px'}}>
                        <span>NASA - </span>This web application is a photo database for all know dings, dents and
                    scratches on fluid lines and sealing surfaces of the Space Shuttle. Used as a reference to determine if the
                    defect has already been addressed.
                </p>
                    </Grid>
                </Grid>

               
            </Grid>
        </>
    );
};