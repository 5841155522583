import { Box} from "@mui/material";
import { Link } from "react-router-dom";

export function SmartHomePage() {
    return (
        <>
            <Box component='img' src='/images/smartHome.jpg' sx={{ width: '200px' }} alt='SmartHome' />
            <h1>Smart Homes &amp; Businesses</h1>
            <Box>
            <p>
                Imagine an intelligent home that is able to know how much power or water the devices in your home are using at any given time. It can tell you when it is time to change
                your air filters, stores all your recipes available on a touch screen in your kitchen. A home where you can see the state of your appliances, garage door or an
                elderly family member from your work. The Internet of Things can now make your home a SmartHome.
            </p>
            </Box>

            <Box>
                <h3>SmartHomes provide:</h3>
                <ul>
                <li><Link to="comfort">Comfort and Convenience</Link></li>
                <li><Link to="efficiency">Improved Efficiency</Link></li>
                <li><Link to="safety">Increased Safety</Link></li>
                <li><Link to="health">Better Health</Link></li>
            </ul>
            </Box>

            <Box>
                <Box component='img' src='/images/smartThingsKit.jpg' sx={{ height: '200px', padding: '10px' }} alt='SmartThings' />
                <Box component='img' src='/images/scale.jpg' sx={{ height: '200px', padding: '10px' }} alt='scale' />
                <Box component='img' src='/images/webcam.jpg' sx={{ height: '200px', padding: '10px' }} alt='webcam' />
            </Box>
        </>
    );
};