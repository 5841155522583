import { Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";

export function HomePage() {
    return (
        <>
            <Grid container spacing={3} >

                <Grid item xs={12} md={6} sx={{ padding: '10px' }}>
                    <br /><br />
                    <Box component='img' src='/images/consulting.jpg' sx={{ width: '250px' }} alt='consulting' />
                    <br /><br />
                    <h3>ColbrenSolutions specializes in </h3>
                    <ul>
                        <li><Link to='/integrations'>Systems Integrations</Link></li>
                        <li><Link to='/webapp'>Web APIs</Link></li>
                        <li><Link to='/tools'>Tools &amp; Automations</Link></li>
                        <li><Link to='/monitoring'>Reporting &amp; Monitioring</Link></li>
                        <li><Link to='/tracking'>Tracking</Link></li>
                        <li><Link to='/smartHome'>SmartHome</Link></li>
                        <li><Link to='/cloud'>Azure Cloud</Link></li>
                    </ul>
                    <br/>
                    <div>
                        <p ><span style={{ fontWeight: '600' }}>Youth sports leagues - </span>
                        <br />develop rosters and trading cards using new ColbrenSports app</p>
                        <a href="https://ColbrenSports.com"> 
                        <img src="/images/colbrenSportsButton.png" style={{ width: '300px' }} alt="ColbrenSports" /></a>
                    </div>
                    <br />

                </Grid>

                <Grid item xs={12} md={6}>
                    <h4>Helping small businesses be more productive</h4>
                    <p>
                        ColbrenSolutions builds tools and applications for small businesses to improve performance and efficiency.
                    </p>
                    
                    <ul>
                        <li>Integrate with others services</li>
                        <li>Build custom CSV importers and exporters</li>
                        <li>Build APIs and Web Applications</li>
                        <li>Build custom internal tools</li>
                        <li>Email and text notification solutions</li>
                        <li>Dynamically generate PDF and Excel docs</li>
                        <li>Smart systems that use calendars, sensors and external events to make decisions or notifications</li>
                        <li>Assist your business moving into the Azure cloud</li>
                    </ul>
                    <br />
                    
                    <Box component='img' src='/images/overview.png' sx={{ width: '250px',margin:'auto',display:'block' }} alt='' />
                    

                </Grid>

            </Grid>
        </>
    );

};