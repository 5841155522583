import { Box} from "@mui/material";

export function WebAppPage() {
    return (
        <>
            <Box component='img' src='/images/webApp.jpg' sx={{ width: '200px' }} alt='Web Apps' />
            <h1>Web Applications &amp; APIs</h1>
            <Box>
                <p>
                Web applications are applications that are created for various users to interface with the system and data. The users may be external customers
                    or internal employees. The web application may be open to the public or just available for a limited few. Web APIs are similar to web applications,
                    but they are meant to retrieve and send data to and from other computer systems. They focus on the data as opposed ot the display and presentation.
                </p>
            </Box>

            <Box>
                <h3>Examples of why you may want to web apps or web API:</h3>
                <ul>
                <li>You have customers that can login and place orders.</li>
                    <li>You have customers that would like to electronically send you orders.</li>
                    <li>You want to make data available to your customers (or their computer systems)</li>
                    <li>You manage data in an Excel sheet and would like a centralized process to share and modify the data.</li>
                </ul>
            </Box>

            <Box>
                <Box component='img' src='/images/projects/dashboardUI.png' sx={{ height: '200px', padding: '10px' }} alt='Dashboard' />
                <Box component='img' src='/images/projects/colbrenHomes.png' sx={{ height: '200px', padding: '10px' }} alt='Colbren Homes' />
                <Box component='img' src='/images/projects/colbrenRunners.png' sx={{ height: '200px', padding: '10px' }} alt='Colbren Runners' />
                <Box component='img' src='/images/projects/colbrenDerby.png' sx={{ height: '200px', padding: '10px' }} alt='Colbren Derby' />
                <Box component='img' src='/images/projects/hydrogenUI.png' sx={{ height: '200px', padding: '10px' }} alt='Hydrogen UI' />
            </Box>
        </>
    );
};