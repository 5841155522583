import { Box} from "@mui/material";

export function TrackingPage() {
    return (
        <>
            <Box component='img' src='/images/projects/sampleMap.png' sx={{ width: '200px' }} alt='tracking map' />
            <h1>Tracking and Timing</h1>
            <Box>
            <p>
                There are many ways and many applications for tracking and measuring people and items. Tracking could include knowing the exact coordinates
                of a person using GPS or other RTLS system or it could be simply determining that someone was in an area using a motion detector. You may not want
                to track a person, but rather just measure their speed, acceleration or other physical movement. We have experience with various tracking technologies.
            </p>
            </Box>

            <Box>
                <h3>Applications of tracking and timing:</h3>
                <ul>
                <li>Position at a given time</li>
                <li>Path over a period of time</li>
                <li>Speed and acceleration</li>
                <li>Activity and area</li>
            </ul>
            </Box>

            <Box>
                <Box component='img' src='/images/projects/hockeyRink.png' sx={{ height: '200px', padding: '10px' }} alt='Hockey rink tracking' />
                <Box component='img' src='/images/projects/gps.png' sx={{ height: '200px', padding: '10px' }} alt='GPS' />
            </Box>

        </>
    );
};